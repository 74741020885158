import React from 'react';
import Action from './action';
import TextBoxImage from 'images/Textbox_Icon.svg';
import DateSignedIcon from 'images/Signature_Date_Icon.svg';
import SignatureIcon from 'images/Signature_Icon.svg';
import CheckboxIcon from 'images/Checkbox_Icon.svg';
import InitialsIcon from 'images/Initials_Icon.svg';

export default function Header(props) {
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: 'white',
        zIndex: 1
      }}
    >
      <h5>Field</h5>
      <div
        className="d-flex gap-3 justify-content-center"
        style={{
          width: '100%',
          marginBottom: 30
        }}
      >
        <Action
          fieldType="signature"
          label={'Signature'}
          img={SignatureIcon}
          active={props.activeAction === 'signature'}
          changeActiveAction={props.changeActiveAction}
        />
        <Action
          fieldType="initials"
          label={'Initials'}
          img={InitialsIcon}
          isLast
          active={props.activeAction === 'initials'}
          changeActiveAction={props.changeActiveAction}
        />
        <Action
          fieldType="date_signed"
          label={'Signature Date'}
          img={DateSignedIcon}
          active={props.activeAction === 'date_signed'}
          changeActiveAction={props.changeActiveAction}
        />
        <Action
          fieldType="checkbox"
          label={'Checkbox'}
          img={CheckboxIcon}
          active={props.activeAction === 'checkbox'}
          changeActiveAction={props.changeActiveAction}
        />
        <Action
          fieldType="text"
          label={'Text Box'}
          img={TextBoxImage}
          isLast
          active={props.activeAction === 'text'}
          changeActiveAction={props.changeActiveAction}
        />
      </div>
    </div>
  );
}
